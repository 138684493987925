<template>
	<v-main>
		<v-container fluid class="h-100">
			<router-view :key="$route.path" />
		</v-container>
	</v-main>
</template>

<script>
export default {
	name: "FullScreenView"
};
</script>
